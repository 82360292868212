<template>
	<div>
		<h2 class="content-block">
			<img src="@/assets/reg_form_header.png" class="header-image" />
			<div class="header-title">Registration Form</div>
			<div class="header-subtitle">(Enhancement - Intensive Review Program)</div>
		</h2>

		<DxPopup v-model:visible="submitError" @hidden="submitError = false" title="Error submitting application"
			:shading="true" shadingColor="#ccca" :showTitle="true" :showCloseButton="true" :dragEnabled="false" :hideOnOutsideClick="true"
			max-width="400px" max-height="280px">
			<div class="submitapp-error-body">{{ submitErrorMsg }}</div>
		</DxPopup>

		<div class="content-block dx-card responsive-paddings application-form" v-if="existingApplication.id && !existingApplication.editing">
			<div class="existing-warning">
				You have already filed an existing application on {{ existingApplication.updated_at_ui }}.<br><br>
				<DxButton icon="edit" type="default" styling-mode="outlined" text="Edit your existing application" @click="editExistingApplication" />
			</div>
		</div>

		<div class="content-block dx-card responsive-paddings application-form" v-if="!existingApplication.id || existingApplication.editing">
			<div v-if="existingApplication.editing" class="editing-warning">
				You are editing your previous application sent on {{ existingApplication.updated_at_ui }}.
			</div>
			<form @submit.prevent="submitApplicationForm" enctype="multipart/form-data">
				<DxForm label-mode="floating" :col-count="3" :form-data="applicationData">
					<template #groupHeading="{ data }">
						<i :class="'dx-icon dx-icon-' + data.caption.split(';')[0]"/><span>{{ data.caption.split(';')[1] }}</span>
					</template>
					<DxGroupItem caption="globe;School and course" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem :col-span="3" css-class="group-hint-header">
							<i class="dx-icon dx-icon-info"></i>
							<span>Click/tap on the boxes and type to search for your school or branch! </span>
							<span v-if="!showAllSchools">(Can't find your school? <a href="#" @click.prevent="forceShowAllSchools">Click here</a> to show all schools.)</span>
							<span v-else>(All schools are being shown - use the dropdown to search for your school.)</span>
						</DxSimpleItem>
						<DxSimpleItem data-field="branch" editor-type="dxSelectBox" :editor-options="branchListOptions" :is-required="true" />
						<DxSimpleItem data-field="school" editor-type="dxSelectBox" :editor-options="schoolsListOptions" :is-required="true" />
						<DxSimpleItem data-field="course" editor-type="dxSelectBox" :editor-options="courseListOptions" :is-required="true" />
						<DxSimpleItem data-field="review_program" editor-type="dxSelectBox" :editor-options="programListOptions" :is-required="true" />
						<DxSimpleItem v-if="needsBoardExamDate" data-field="board_exam_date" editor-type="dxDateBox" :editor-options="{ type: 'date', pickerType: 'native', dateSerializationFormat: 'yyyy-MM-dd' }" :is-required="true" />
					</DxGroupItem>
					<DxGroupItem caption="user;Name and personal details" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="last_name" :is-required="true" :label="{ text: 'Last/Family name' }" />
						<DxSimpleItem data-field="first_name" :is-required="true" />
						<DxSimpleItem data-field="middle_name" :is-required="true" />
						<DxSimpleItem data-field="gender" editor-type="dxSelectBox" :editor-options="genderListOptions" :is-required="true" />
						<DxSimpleItem data-field="birthday" editor-type="dxDateBox" :editor-options="{ type: 'date', pickerType: 'native', max: Date(), dateSerializationFormat: 'yyyy-MM-dd' }" :is-required="true" />
					</DxGroupItem>
					<DxGroupItem caption="tel;Contact details" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="mobile" :is-required="true" :editor-options="{ mode: 'tel', mask: '\\0\\900-000-0000', maskInvalidMessage: 'Mobile must be like 0912-345-6789' }">
							<DxRequiredRule message="Mobile is required" />
						</DxSimpleItem>
						<DxSimpleItem data-field="email" :is-required="true">
							<DxRequiredRule message="Email is required"/><DxEmailRule message="Email is invalid"/>
						</DxSimpleItem>
						<DxSimpleItem data-field="facebook" :is-required="true" :label="{ text: 'Facebook Messenger' }" />
						<DxSimpleItem data-field="address" :is-required="true" :col-span="3" editor-type="dxTextArea" :editor-options="{ autoResizeEnabled: true, maxHeight: '180px' }" />
					</DxGroupItem>
					<DxGroupItem caption="errorcircle;In case of emergency, please notify:" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="emergency_name" :label="{ text: 'Full name' }" :is-required="true" />
						<DxSimpleItem data-field="emergency_phone" :label="{ text: 'Phone number' }" :is-required="true" />
						<DxSimpleItem data-field="emergency_relationship" :label="{ text: 'Relationship' }" :is-required="true" />
						<DxSimpleItem data-field="emergency_address" :label="{ text: 'Address to contact' }" :is-required="true" :col-span="3" editor-type="dxTextArea" :editor-options="{ autoResizeEnabled: true, maxHeight: '180px' }" />
					</DxGroupItem>
					<DxGroupItem caption="image;Photos and files" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="photo" template="uploadFileDrop" />
					</DxGroupItem>
					<template #uploadFileItem="{ data }">
						<DxFileUploader :name="data.dataField"
							select-button-text="Select photo" :label-text="data.dataField" accept="image/*"
							upload-mode="useForm" :input-attr="{ 'aria-label': 'Select Photo' }"
							@valueChanged="fileUploadChanged($event, data.dataField)"
						/>
					</template>
					<template #uploadFileDrop="{ data }">
						<DropzoneFile :label="data.editorOptions.label" :initial="existingApplication.editing ? applicationData.attachments.find(a => a.filemime.startsWith('image')) : null"
							@valueChanged="fileUploadChanged($event, data.dataField)" />
					</template>
					<DxEmptyItem :col-span="2" />
					<DxButtonItem :col-span="3" :button-options="{ type: 'success', text: existingApplication.editing ? 'Update and Continue to Survey' : 'Continue to Survey', useSubmitBehavior: true }" />
				</DxForm>
			</form>
			<DxLoadPanel :position="{ of: '.application-form' }" :shading="true" shadingColor="rgba(0,0,0,0.4)" v-model:visible="loadingVisible" />
		</div>
	</div>
</template>

<script>
import { DxForm, DxSimpleItem , DxGroupItem, DxButtonItem, DxEmptyItem, DxRequiredRule, DxEmailRule } from "devextreme-vue/form";
import DxTextArea from 'devextreme-vue/text-area';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxPopup } from 'devextreme-vue/popup';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import DropzoneFile from '../components/dropzone-file';
import DxButton from 'devextreme-vue/button';
import { inject, ref, computed } from "vue";
import { useRouter } from 'vue-router';
import to from 'await-to-js';
import notify from 'devextreme/ui/notify';
import schools from "../schools.js";
import branches from "../branches.js";
import courses from "../courses.js";
import DataSource from "devextreme/data/data_source";

export default {
	setup() {
		const $http = inject('$http');
		const colCountByScreen = {
			xs: 1,
			sm: 2,
			md: 3,
			lg: 4
		}
		const router = useRouter();

		const existingApplication = ref({ id: false, updated_at: '', updated_at_ui: '', editing: false });
		const applicationData = ref({
			course: 'NLE',
		});
		let loadingVisible = ref(false);
		let submitError = ref(false);
		let submitErrorMsg = ref('');

		$http.get('/user/me').then((response) => {
			if (Array.isArray(response.data.applications) && response.data.applications.length >= 1) {
				existingApplication.value = response.data.applications[0];
				existingApplication.value.updated_at_ui  = (new Date(existingApplication.value.updated_at)).toLocaleString('en-HK');
				existingApplication.value.editing = false;
			} else {
				existingApplication.value.id = false;
			}
		});

		// Request to edit existing application - load from server all existing data from the application and populate our applicationData.
		const editExistingApplication = () => {
			if (!existingApplication.value.id)
				return;
			loadingVisible.value = true;
			$http.get('/application/' + existingApplication.value.id).then((response) => {
				loadingVisible.value = false;
				applicationData.value = response.data;
				if (Array.isArray(response.data.attachments)) {
					let imageAttachment = response.data.attachments.find(a => a.filemime.startsWith('image'));
					if (imageAttachment)
						fileUploadChanged(imageAttachment, 'photo');
				}
				existingApplication.value.editing = true;
				console.log(applicationData.value);
			}).catch((ex) => {
				console.error(ex);
				loadingVisible.value = false;
				existingApplication.value.editing = false;
				notify({ message: "Couldn't load your existing application. Please contact staff!", position: 'center', shading: true, shadingColor: '#cccc' }, "error", 3000);
			});
		};

		// Filter schools list when a branch is selected.
		const showAllSchools = ref(false);
		const forceShowAllSchools = () => {
			showAllSchools.value = true;
			filterSchoolsList({ value: '' });
		};
		const filterSchoolsList = (e) => {
			let filterValue = branches.find(b => b.code == e.value);
			filterValue = (filterValue && filterValue.region) ? filterValue.region : null;
			if (!filterValue || showAllSchools.value) {
				schoolsData.filter(null);
				schoolsData.load();
				return;
			}
			schoolsData.filter([ 'region', '=', filterValue ]);		// NB: Case sensitive!
			schoolsData.load();
		};

		// TODO: Load from server?
		const courseData = new DataSource({ store: courses });
		const branchData = new DataSource({ store: branches });
		const branchListOptions = { dataSource: branchData, valueExpr: 'code', displayExpr: 'title', searchEnabled: true, onValueChanged: filterSchoolsList };
		const schoolsData = new DataSource({ store: schools, sort: 'title', pageSize: 10, paginate: true });
		const schoolsListOptions = { dataSource: schoolsData, valueExpr: 'slug', displayExpr: 'title', searchEnabled: true };
		const courseListOptions = { dataSource: courseData, valueExpr: 'code', displayExpr: 'title', searchEnabled: true };
		const genderListOptions = { dataSource: [ 'Male', 'Female', 'Diverse' ] };
		const programListOptions = {
			grouped: true,
			displayExpr: 'name',
			valueExpr: 'id',
			dataSource: [
				{ key: 'Enhancement', items: [ { id: 'year3', name: '3rd year' }, { id: 'year4', name: '4th year' }, { id: 'year5', name: '5th year' }, { id: 'year6', name: '6th year' } ] },
				{ key: 'Intensive', items: [ { id: 'take1', name: '1st taker' }, { id: 'takere', name: 'Retaker' } ] }
			]
		};

		const submitApplicationForm = async () => {
			console.log("Posting data", applicationData.value);
			loadingVisible.value = true;
			let returnVal, err;
			let postUrl = existingApplication.value.editing ? ('/application/' + existingApplication.value.id) : ('/application');
			applicationData.value['_method'] = existingApplication.value.editing ? 'PUT' : 'POST';
			[ err, returnVal ] = await to($http.post(postUrl, applicationData.value));
			loadingVisible.value = false;
			if (err) {
				console.error([ err ]);
				let errorMsg = err.message ? err.message : 'Unknown error';
				if (err.response && err.response.statusText) {
					errorMsg += "\n" + err.response.statusText;
				}
				submitError.value = true;
				submitErrorMsg.value = "Error submitting your form:\n" + errorMsg;
				return;
			}
			console.log(returnVal);
			notify({ message: "Your application has been " + (existingApplication.value.ediiting ? 'updated' : 'submitted') + " successfully!", position: 'center', shading: true, shadingColor: '#cccc' }, "success", 3000);
			setTimeout(() => {
				router.push("/survey");
			}, 3000);
		}

		const fileUploadChanged = (e, dataField) => {
			// console.log("fileUploadChanged: ", dataField, e);
			applicationData.value[dataField] = e.id;
		}

		const needsBoardExamDate = computed(() => {
			return 'review_program' in applicationData.value && String(applicationData.value['review_program']).startsWith('take');
		});

		return {
			colCountByScreen,
			applicationData,
			submitApplicationForm,
			loadingVisible,
			fileUploadChanged,
			schoolsListOptions,
			branchListOptions,
			courseListOptions,
			genderListOptions,
			programListOptions,
			needsBoardExamDate,
			showAllSchools, forceShowAllSchools,
			existingApplication,
			submitError, submitErrorMsg,
			editExistingApplication,
		};
	},
	components: {
		DxForm, DxSimpleItem, DxGroupItem, DxButtonItem, DxEmptyItem, DxRequiredRule, DxEmailRule, DxLoadPanel, DxFileUploader, DropzoneFile, DxPopup, DxButton,
		DxTextArea, DxSelectBox, DxDateBox // eslint-disable-line vue/no-unused-components
	}
};
</script>

<style>
.header-image {
	width: 100%;
	max-width: 1200px;
}
.header-title {
	text-align: center;
	text-transform: uppercase;
	width: 100%; max-width: 1200px;
	border-bottom: 1px solid black;
}
.header-subtitle {
	text-align: center;
	width: 100%; max-width: 1200px;
	font-size: 20px;
}
.application-form {
	max-width: 1200px;
}
.application-form .existing-warning {
	text-align: center;
}
.group-hint-header {
	font-style: italic;
	padding: 0 !important;
}
.group-hint-header span {
	vertical-align: middle;
}
.group-hint-header i {
	vertical-align: middle;
	padding-right: 5px;
}
.submitapp-error-body {
	white-space: pre-line;
	border-left: 2px solid red;
	padding-left: 15px;
}
.application-form .editing-warning {
	border: 1px solid var(--dx-color-primary);
    margin-bottom: 1em;
    padding: 5px 10px;
    background: var(--dx-color-primary);
    border-radius: 5px;
    color: var(--dx-color-main-bg);
    font-style: italic;
}
</style>
