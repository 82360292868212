<template>
	<div>
		<h2 class="content-block">Employees Management</h2>

		<dx-data-grid style="width:100%;"
			class="dx-card wide-card"
			:data-source="dataSourceConfig"
			:focused-row-index="0"
			:show-borders="false"
			:focused-row-enabled="true"
			:column-auto-width="false"
			:column-hiding-enabled="true"
			:allowColumnResizing="true" columnResizingMode="widget"
			@exporting="onExporting"
		>
			<dx-state-storing :enabled="false" type="localStorage" storage-key="manage-employees" />
			<dx-column-chooser :enabled="true" mode="select" :searchEnabled="true" height="400px">
				<dx-position my="right top" at="right bottom" of=".dx-datagrid-column-chooser-button" />
			</dx-column-chooser>
			<dx-paging :page-size="10" />
			<dx-pager :show-page-size-selector="true" :show-info="true" />
			<dx-filter-row :visible="true" />
			<dx-header-filter :visible="true" />
			<dx-search-panel :visible="true" width="300px" />
			<dx-toolbar>
				<dx-item location="before" name="searchPanel" />
				<dx-item location="after" name="dateLengthButton">
					<dx-button icon="refresh" hint="Refresh data" />
				</dx-item>
				<dx-item name="exportButton" location="after" />
				<dx-item name="columnChooserButton" location="after" />
			</dx-toolbar>
			<dx-scrolling :useNative="true" />
			<dx-export :enabled="true" :formats="['xlsx', 'pdf']" />

			<dx-column data-field="id" data-type="number" caption="ID" :width="80" :allow-header-filtering="false" />
			<dx-column data-field="name" caption="Name" :width="200" :allow-header-filtering="false" />
			<dx-column data-field="role" caption="Role" :width="100" :allow-header-filtering="true" />
			<dx-column data-field="email" caption="Email" :width="200" :allow-header-filtering="false" />
			<dx-column data-field="mobile" caption="Mobile" :width="200" :allow-header-filtering="false" />
			<dx-column data-field="facebook" caption="Facebook Messenger" :width="200" :allow-header-filtering="false" />
			<dx-column data-field="last_access" data-type="datetime" caption="Last activity" :width="160" :allow-header-filtering="false" />
			<dx-column data-field="created_at" data-type="datetime" caption="User created" :width="160" :allow-header-filtering="false" />
		</dx-data-grid>
	</div>
</template>

<script>
import CustomStore from 'devextreme/data/custom_store';
import DxDataGrid, {
	DxColumn,
	DxFilterRow,
	DxPager,
	DxPaging,
	DxColumnChooser,
	DxPosition,
	DxStateStoring,
	DxToolbar,
	DxItem,
	DxSearchPanel,
	DxScrolling,
	DxHeaderFilter,
	DxExport
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';
import { inject } from "vue";
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';

export default {
	setup() {
		const $http = inject('$http');
		const dataSourceConfig = new CustomStore({
			key: 'id',
			loadMode: 'raw',
			remoteOperations: false,
			load: () => {
				return $http.get('/users').then(response => response.data);
			}
		});
		const onExporting = (e) => {
			let dateCode = (new Date()).toISOString().substring(0, 10);
			if (e.format == 'xlsx') {
				const workbook = new Workbook();
				const worksheet = workbook.addWorksheet('Main sheet');
				exportDataGrid({
					component: e.component,
					worksheet: worksheet,
					customizeCell: function(options) {
						options.excelCell.font = { name: 'Arial', size: 12 };
						options.excelCell.alignment = { horizontal: 'left' };
					} 
				}).then(function() {
					workbook.xlsx.writeBuffer()
						.then(function(buffer) {
							saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Employees-' + dateCode + '.xlsx');
						});
				});
			} else if (e.format == 'pdf') {
				const doc = new jsPDF();
				exportDataGridToPdf({
					jsPDFDocument: doc,
					component: e.component
				}).then(() => {
					doc.save('Employees-' + dateCode + '.pdf');
				});
			}
		};

		return {
			dataSourceConfig,
			onExporting
		};
	},
	components: {
		DxDataGrid,
		DxColumn,
		DxFilterRow,
		DxPager,
		DxPaging,
		DxColumnChooser,
		DxPosition,
		DxStateStoring,
		DxToolbar,
		DxItem,
		DxButton,
		DxSearchPanel,
		DxScrolling,
		DxHeaderFilter,
		DxExport
	}
};
</script>
