<template>
	<div>
		<h2 class="content-block">
			<img src="@/assets/reg_form_header.png" class="header-image" />
			<div class="header-title">Survey Form</div>
		</h2>

		<DxPopup v-model:visible="videoPopup" :shading="true" shadingColor="#ccca" :showTitle="true" :showCloseButton="true" :dragEnabled="false" :hideOnOutsideClick="true" :wrapper-attr="{ class: 'welcome-popup' }"
			max-width="480px" max-height="832px" :fullScreen="smallScreenVideo"
			:onShown="videoPopupToggled" :onHiding="videoPopupToggled">
			<video controls src="../assets/WelcomeVideo.mp4" ref="welcomeVideo" width="100%" height="100%"/>
		</DxPopup>

		<DxPopup v-model:visible="submitError" @hidden="submitError = false" title="Error submitting survey"
			:shading="true" shadingColor="#ccca" :showTitle="true" :showCloseButton="true" :dragEnabled="false" :hideOnOutsideClick="true"
			max-width="400px" max-height="280px">
			<div class="submitsurvey-error-body">{{ submitErrorMsg }}</div>
		</DxPopup>

		<div class="content-block dx-card responsive-paddings application-form" v-if="existingSurvey.id && !existingSurvey.editing">
			<div class="existing-warning">
				You have already filed an existing survey on {{ existingSurvey.updated_at_ui }}.<br><br>
				<DxButton icon="edit" type="default" styling-mode="outlined" text="Edit your existing survey" @click="editExistingSurvey" />
			</div>
		</div>

		<div class="content-block dx-card responsive-paddings application-form" v-if="!existingSurvey.id || existingSurvey.editing">
			<form @submit.prevent="submitApplicationForm" enctype="multipart/form-data">
				<DxForm id="survey-dxform" label-mode="floating" :col-count="3" :form-data="applicationData">
					<template #groupHeading="{ data }">
						<i v-if="data.caption.split(';')[0]" :class="'dx-icon dx-icon-' + data.caption.split(';')[0]"/><span>{{ data.caption.split(';')[1] }}</span>
					</template>
					<DxSimpleItem :col-span="3" :visible="current_job_status == ''">
						<DxBox :direction="smallScreenVideo ? 'col' : 'row'" width="100%" class="simple-item-descriptive dx-card dx-theme-accent-as-border-color">
							<DxItem :ratio="smallScreenVideo ? 0 : 1" :baseSize="smallScreenVideo ? 'auto' : 0">
								<p>We are excited to announce a new initiative aimed at supporting nursing students in the final phase of their education. Brainhub, in collaboration with ProCare International Ltd., a Swiss company dedicated to advancing healthcare careers, is exploring the creation of a platform called 'Colpuno.'</p>
								<p>This platform aims to connect nursing graduates with their ideal employers, ensuring all legal and ethical considerations are met. To make this vision a reality, we need your help. We have designed a brief survey to gather insights and preferences from nursing students like you. Your feedback will be invaluable in shaping a platform that meets your needs and helps you achieve your career goals.</p>
								<p>This survey will take only 5 minutes of your time. By participating, you will be contributing to the development of a resource that could significantly improve job matching for nurses. Your responses will help us understand the challenges you face and the type of support you need as you transition from education to employment.</p>
								<p>We appreciate your time and input, and we believe that with your help, we can create a brighter future for nurses. Thank you for your participation!</p>
							</DxItem>
							<DxItem :ratio="smallScreenVideo ? 0 : 0" :baseSize="smallScreenVideo ? 300 : 200">
								<div class="simple-item-videobox" @click="videoPopup = true">
									<div><i style="font-size:48px;" class="dx-icon-video"></i></div>
									<span>Play Video</span>
								</div>
							</DxItem>
						</DxBox>
					</DxSimpleItem>
					<DxGroupItem caption="Do you already have a job offer / contract for employment after passing PNLE?" :col-span="3" :col-count="2">
						<DxButtonItem :button-options="{ type: current_job_status == 'yes' ? 'default' : 'normal', icon: 'taskcomplete', text: 'Yes - I have a job offer or contract',
							useSubmitBehavior: false, width: '100%', height: current_job_status != '' ? '40px' : '100px', onClick: current_job_statusSet }" />
						<DxButtonItem :button-options="{ type: current_job_status == 'no' ? 'default' : 'normal', icon: 'taskrejected', text: 'No - I do not have a job offer or contract',
							useSubmitBehavior: false, width: '100%', height: current_job_status != '' ? '40px' : '100px', onClick: current_job_statusSet }" />
					</DxGroupItem>
					<DxGroupItem :visible="current_job_status == 'yes'" caption="Employer Details" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="employer_site" editor-type="dxSelectBox" :editor-options="employerSiteOptions" :is-required="true" />
						<DxSimpleItem :visible="applicationData.employer_site == 'national'" :is-required="applicationData.employer_site == 'national'"
							data-field="employer_region" editor-type="dxSelectBox" :editor-options="employerSiteNational" :col-span="2"  />
						<DxSimpleItem :visible="applicationData.employer_site == 'international'" :is-required="applicationData.employer_site == 'international'"
							data-field="employer_country" editor-type="dxSelectBox" :editor-options="employerSiteInternational" :col-span="2" />
						<DxEmptyItem :visible="!applicationData.employer_site" :col-span="2" />
						<DxSimpleItem data-field="employer_kind" editor-type="dxSelectBox" :editor-options="employerKindOptions" :is-required="true" :col-span="1" />
						<DxSimpleItem :visible="!!needsEmployerKindSpecifics" :is-required="!!needsEmployerKindSpecifics" data-field="employer_kind_specifics" :label="{ text: needsEmployerKindSpecifics }" :col-span="2" />
					</DxGroupItem>
					<DxGroupItem :visible="current_job_status == 'yes'" caption="Job Specifics" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="job_position" editor-type="dxSelectBox" :editor-options="jobPositionOptions" :is-required="true" />
						<DxSimpleItem data-field="job_position_area" :is-required="true" editor-type="dxSelectBox" :editor-options="jobPositionAreaOptions" :label="{ text: 'Area/unit/job description' }" :col-span="2" />
						<DxSimpleItem data-field="job_duration_ends" editor-type="dxDateBox" :editor-options="{ type: 'date', pickerType: 'rollers', dateSerializationFormat: 'yyyy-MM-01', displayFormat: 'monthAndYear' }" :is-required="true" />
						<DxSimpleItem data-field="job_intent" editor-type="dxSelectBox" :editor-options="jobIntentOptions" :label="{ text: 'Your intention after' }" :is-required="true" :col-span="2" />
						<DxEmptyItem :col-span="1" />
						<DxSimpleItem data-field="job_intent_same_place" editor-type="dxCheckBox" :editor-options="{ text: 'I wish to stay in the same place as my current position' }"
							:col-span="2" :label="{ visible: false }" />
					</DxGroupItem>
					<DxGroupItem :visible="current_job_status == 'no'" caption="Job Application" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="employment_plans" editor-type="dxSelectBox" :editor-options="employmentPlansOptions" :is-required="true" :col-span="3" />
						<DxSimpleItem data-field="employment_applied_to_regions" :label="{ text: 'Where have you applied to?' }"
							:visible="current_job_status == 'no' && 'employment_plans' in applicationData && applicationData.employment_plans == 'applied-already-national'"
							:is-required="current_job_status == 'no' && 'employment_plans' in applicationData && applicationData.employment_plans == 'applied-already-national'"
							editor-type="dxTagBox" :editor-options="employmentAppliedToRegionsOptions" :col-span="3"
						/>
						<DxSimpleItem data-field="employment_applied_to_countries" :label="{ text: 'Where have you applied to?' }"
							:visible="current_job_status == 'no' && 'employment_plans' in applicationData && applicationData.employment_plans == 'applied-already-international'"
							:is-required="current_job_status == 'no' && 'employment_plans' in applicationData && applicationData.employment_plans == 'applied-already-international'"
							editor-type="dxTagBox" :editor-options="employmentAppliedToCountriesOptions" :col-span="3"
						/>
						<DxSimpleItem data-field="employment_no_job_reason" :label="{ text: 'Why not apply for a job?' }"
							:visible="current_job_status == 'no' && 'employment_plans' in applicationData && applicationData.employment_plans == 'not-applying'"
							:is-required="current_job_status == 'no' && 'employment_plans' in applicationData && applicationData.employment_plans == 'not-applying'"
							editor-type="dxTextArea" :col-span="3"
						/>
					</DxGroupItem>
					<DxGroupItem :visible="needsJobPrefSpecifics" caption="Preferred work environment" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="job_prefer_employer_kind" :label="{ text: 'What kind of employer would you prefer?' }"
							:is-required="needsJobPrefSpecifics" editor-type="dxSelectBox" :editor-options="jobPreferEmployerKindOptions" />
						<DxSimpleItem data-field="job_prefer_employer_site" :label="{ text: 'What is your preferred choice of employer site?' }"
							:is-required="needsJobPrefSpecifics" editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteOptions" />
						<DxSimpleItem data-field="job_prefer_employer_region" :label="{ text: 'Preferred Region' }"
							:visible="applicationData.job_prefer_employer_site == 'national'" :is-required="needsJobPrefSpecifics && applicationData.job_prefer_employer_site == 'national'"
							editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteNational" />
						<DxSimpleItem data-field="job_prefer_employer_country" :label="{ text: 'Preferred Country' }"
							:visible="applicationData.job_prefer_employer_site == 'international'" :is-required="needsJobPrefSpecifics && applicationData.job_prefer_employer_site == 'international'"
							editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteInternational"  />
						<DxEmptyItem :visible="!applicationData.job_prefer_employer_site" />
						<DxSimpleItem data-field="job_prefer_employer_position" :label="{ text: 'Preferred Job Position' }"
							editor-type="dxSelectBox" :editor-options="jobPreferEmployerPositionOptions" :col-span="3" :is-required="needsJobPrefSpecifics" />
						<DxSimpleItem data-field="job_prefer_employer_position_desc" :label="{ text: 'Preferred Area / Job Description' }" :col-span="3" editor-type="dxSelectBox" :editor-options="jobPositionAreaOptions" />
					</DxGroupItem>

					<DxGroupItem :col-span="3" :col-count="3" :visible="current_job_status != ''">
						<DxSimpleItem :col-span="2">
							<template #default>
								<DxBox direction="row">
									<DxItem :ratio="0" :baseSize="40">
									</DxItem>
									<DxItem :ratio="1">
										By participating in this survey, you allow ProCare International Ltd. to utilize the information provided to enhance career planning and development tools for nurses. This consent helps to tailor resources like the Colpuno matching platform to better serve your needs and aspirations in the nursing profession. Your input is invaluable in shaping innovative solutions that support your career growth.
									</DxItem>
								</DxBox>
								<DxBox direction="row" style="margin-top:1em;">
									<DxItem :ratio="0" :baseSize="40">
										<DxCheckBox v-model:value="applicationData.allow_profile_matching" />
									</DxItem>
									<DxItem :ratio="1">
										I agree to the use of my information for these purposes. 
									</DxItem>
								</DxBox>
								<DxBox direction="row" style="margin-top:1em;">
									<DxItem :ratio="0" :baseSize="40">
										<DxCheckBox v-model:value="applicationData.agree_privacy_policy" />
									</DxItem>
									<DxItem :ratio="1">
										<div><div>I have read and agree with the <a href="/privacy.html" target="_blank">Privacy Policy</a> regarding usage of my data.</div></div>
									</DxItem>
								</DxBox>
							</template>
						</DxSimpleItem>
						<DxButtonItem vertical-alignment="bottom" :visible="current_job_status != ''" :col-span="1" :button-options="{ type: 'success', text: 'Submit Survey', useSubmitBehavior: true }" />
					</DxGroupItem>
				</DxForm>
			</form>
			<DxLoadPanel :position="{ of: '.application-form' }" :shading="true" shadingColor="rgba(0,0,0,0.4)" v-model:visible="loadingVisible" />
		</div>
	</div>
</template>

<script setup>
import { DxForm, DxSimpleItem , DxGroupItem, DxButtonItem, DxEmptyItem } from "devextreme-vue/form";
import DxTextArea from 'devextreme-vue/text-area';			// eslint-disable-line no-unused-vars
import DxSelectBox from 'devextreme-vue/select-box';		// eslint-disable-line no-unused-vars
import { DxDateBox } from 'devextreme-vue/date-box';		// eslint-disable-line no-unused-vars
import DxTagBox from 'devextreme-vue/tag-box';				// eslint-disable-line no-unused-vars
import DxCheckBox from 'devextreme-vue/check-box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxBox, DxItem } from 'devextreme-vue/box';
import DxPopup from "devextreme-vue/popup"
import DxButton from 'devextreme-vue/button';
import { inject, ref, computed } from "vue";
import { useRouter } from 'vue-router';
import to from 'await-to-js';
import notify from 'devextreme/ui/notify';
import countries from '../countries.js';
import regions from "../regions.js";
import specialities from "../specialities.js";
import { sizes } from '../utils/media-query';

const $http = inject('$http');
const colCountByScreen = {									// eslint-disable-line no-unused-vars
	xs: 1,
	sm: 2,
	md: 3,
	lg: 4
}
const videoPopup = ref(false);
const welcomeVideo = ref(null);
const smallScreenVideo = sizes()['screen-x-small'] || sizes()['screen-small'];
const router = useRouter();

const current_job_status = ref('');			// Values: '', 'yes' or 'no'
const current_job_statusSet = function(ev) {
	let newValue = ev.element.innerText.toLowerCase().includes('yes') ? 'yes' : 'no';				// TODO: Oh god, figure out a better way!
	current_job_status.value = newValue == current_job_status.value ? '' : newValue;		// Click to cancel status
}

const existingSurvey = ref({ id: false, updated_at: '', updated_at_ui: '', editing: false });
const applicationData = ref({
	allow_profile_matching: true,
	agree_privacy_policy: false,
});
let loadingVisible = ref(false);
let submitError = ref(false);
let submitErrorMsg = ref('');

$http.get('/user/me').then((response) => {
	if (Array.isArray(response.data.surveys) && response.data.surveys.length >= 1) {
		existingSurvey.value = response.data.surveys[0];
		existingSurvey.value.updated_at_ui  = (new Date(existingSurvey.value.updated_at)).toLocaleString('en-HK');
		existingSurvey.value.editing = false;
	} else {
		existingSurvey.value.id = false;
	}
});

// Request to edit existing survey - load from server all existing data from the survey and populate our applicationData.
const editExistingSurvey = () => {
	if (!existingSurvey.value.id)
		return;
	loadingVisible.value = true;
	$http.get('/survey/' + existingSurvey.value.id).then((response) => {
		loadingVisible.value = false;
		applicationData.value = response.data;
		if (applicationData.value.job_intent_same_place === null)
			applicationData.value.job_intent_same_place = false;
		current_job_status.value = response.data.current_job_status ? 'yes' : 'no';
		existingSurvey.value.editing = true;
		console.log(applicationData.value);
	}).catch((ex) => {
		console.error(ex);
		loadingVisible.value = false;
		existingSurvey.value.editing = false;
		notify({ message: "Couldn't load your existing survey. Please contact staff!", position: 'center', shading: true, shadingColor: '#cccc' }, "error", 3000);
	});
};


const employerSiteOptions = { dataSource: [ { val: 'national', label: 'National Employer' }, { val: 'international', label: 'International Employer' } ], valueExpr: 'val', displayExpr: 'label' };
const employerSiteNational = { dataSource: regions, valueExpr: 'code', displayExpr: 'name', searchEnabled: true };
const employerSiteInternational = { dataSource: countries, valueExpr: 'code', displayExpr: 'name', searchEnabled: true };
const employerKindOptions = {
	grouped: true,
	displayExpr: 'name',
	valueExpr: 'id',
	dataSource: [
		{ key: "Hospital", items: [ { id: 'hospital-primary', name: 'Primary Hospital' }, { id: 'hospital-secondary', name: 'Secondary Hospital' }, { id: 'hospital-tertiary', name: 'Tertiary Hospital' } ] },
		{ key: "", items: [
			{ id: "community", name: "Community" },
			{ id: "company-school", name: "Company/School" },
			{ id: "nursing-home", name: "Nursing home" },
			{ id: "elderly-home", name: "Elderly home" },
			{ id: "non-sector", name: "Non health-care sector" },
			{ id: "other", name: "Other" },
		] },
	],
};
const needsEmployerKindSpecifics = computed(() => {
	let otherLabel = 'employer_kind' in applicationData.value ? String(applicationData.value['employer_kind']) : null;
	if (!otherLabel)
		return false;
	if (otherLabel == 'non-sector')
		return "Other sector of employer";
	if (otherLabel == 'other')
		return "Other type of employer";
	return false;
});
const jobPositionOptions = {
	dataSource: [ { id: 'no-contact', name: 'No direct contact with patients' }, { id: 'ambulatory', name: 'Direct contact with Ambulatory patients' }, { id: 'stationary', name: 'Direct contact with stationary patients' } ],
	displayExpr: 'name', valueExpr: 'id',
};
const jobPositionAreaOptions = {
	acceptCustomValue: true,
	displayExpr: 'name', valueExpr: 'name', grouped: true, 
	placeholder: "Select or type a custom item...",
	onCustomItemCreating: (args) => {
		// Create a new item under the 'other' group.
		if (!args.text) {
			args.customItem = null;
			return;
		}
		const { component, text } = args;
		const currentItems = component.option('items');
		const otherGroup = currentItems.find(cu => cu.key == 'Other');
		if (!otherGroup || !otherGroup.items[0]) {
			args.customItem = null;
			return;
		}
		otherGroup.items[0].name = text.trim();
		args.customItem = otherGroup.items[0];
	},
	dataSource: specialities,
};

const jobIntentOptions = {
	grouped: true,
	displayExpr: 'name',
	valueExpr: 'id',
	dataSource: [
		{ key: "Do you intent to stay with this employer for a long time or do you aim for another destination?", items: [
			{ id: "long", name: "I intend to stay with this employer longer than 2 years" },
			{ id: "short", name: "I plan to stay in this job for a maximum of 2 years and then I would like a new job opportunity." },
		] },
	],
}
const needsJobPrefSpecifics = computed(() => {
	return (current_job_status.value == 'yes' && 'job_intent' in applicationData.value && String(applicationData.value['job_intent']) == 'short') || (current_job_status.value == 'no');
});

const jobPreferEmployerKindOptions = {
	grouped: true,
	displayExpr: 'name',
	valueExpr: 'id',
	dataSource: [
		{ key: "Hospital", items: [ { id: 'hospital-primary', name: 'Primary Hospital' }, { id: 'hospital-secondary', name: 'Secondary Hospital' }, { id: 'hospital-tertiary', name: 'Tertiary Hospital' }, { id: 'hospital-any', name: 'Any Hospital (does not matter)' } ] },
		{ key: "", items: [
			{ id: "community", name: "Community" },
			{ id: "company-school", name: "Company/School" },
			{ id: "nursing-home", name: "Nursing home" },
			{ id: "elderly-home", name: "Elderly home" },
			{ id: "non-sector", name: "Non health-care sector" },
			{ id: "other", name: "Other" },
		] },
	],
}

const jobPreferEmployerSiteOptions = { dataSource: [ { val: 'national', label: 'National Employer' }, { val: 'international', label: 'International Employer' } ], valueExpr: 'val', displayExpr: 'label' };
const jobPreferEmployerSiteNational = { dataSource: regions, valueExpr: 'code', displayExpr: 'name', searchEnabled: true  };
const jobPreferEmployerSiteInternational = { dataSource: countries, valueExpr: 'code', displayExpr: 'name', searchEnabled: true };
const jobPreferEmployerPositionOptions = {
	dataSource: [
		{ id: 'no-contact-admin', name: 'No direct contact with patients (work in administration)' },
		{ id: 'no-contact-telecom', name: 'No direct contact with patients (telecommunication advisory)' },
		{ id: 'ambulatory', name: 'Direct contact with ambulatory patient (i.e. OPD, company nurse, school nurse)' },
		{ id: 'stationary', name: 'Direct contact with stationary patients (i.e. general ward, ms ward)' },
		{ id: 'specialist', name: 'Specialized unit (i.e. OR, ER, ICU)' },
		{ id: 'further-edu', name: 'Aim for further education (i.e. teaching, studying to become a doctor)' },
		{ id: 'no-preference', name: 'No preference' },
	],
	displayExpr: 'name', valueExpr: 'id',
};

const employmentPlansOptions = {
	grouped: true,
	displayExpr: 'name',
	valueExpr: 'id',
	dataSource: [
		{ key: "I have applied for a job", items: [
			{ id: "applied-examwait", name: "Not yet but I will soon after passing the exam" },
			{ id: "applied-already-national", name: "Yes, I applied for a job nationally but without success/waiting for feedback" },
			{ id: "applied-already-international", name: "Yes, I applied for a job internationally but without success/waiting for feedback" },
			{ id: "not-applying", name: "I won't apply for a job" },
		] },
	],
};

const employmentAppliedToRegionsOptions = {
	displayExpr: 'name', valueExpr: 'code', searchEnabled: true,
	dataSource: regions,
};
const employmentAppliedToCountriesOptions = {
	displayExpr: 'name', valueExpr: 'code', searchEnabled: true,
	dataSource: countries,	
}

const submitApplicationForm = async () => {
	// TODO: Verify dates submitted via date picker with rollers disable don't overflow, e.g. 27/2/2024 might become .../3/2024!
	if (!applicationData.value.agree_privacy_policy) {
		alert("Sorry, you must agree to the privacy policy before submitting your survey!");
		return;
	}
	
	applicationData.value.current_job_status = Boolean(current_job_status.value == 'yes');
	if (applicationData.value.job_intent_same_place === null)
		applicationData.value.job_intent_same_place = false;
	console.log("Posting data", applicationData.value);
	loadingVisible.value = true;
	let returnVal, err;
	let postUrl = existingSurvey.value.editing ? ('/survey/' + existingSurvey.value.id) : ('/survey');
	applicationData.value['_method'] = existingSurvey.value.editing ? 'PUT' : 'POST';
	[ err, returnVal ] = await to($http.post(postUrl, applicationData.value));
	loadingVisible.value = false;
	if (err) {
		console.error([ err ]);
		let errorMsg = err.message ? err.message : 'Unknown error';
		if (err.response && err.response.statusText) {
			errorMsg += "\n" + err.response.statusText;
		}
		submitError.value = true;
		submitErrorMsg.value = "Error submitting your form:\n" + errorMsg;
		return;
	}
	console.log(returnVal);
	notify({ message: "Your survey has been submitted successfully!", position: 'center', shading: true, shadingColor: '#cccc' }, "success", 3000);
	setTimeout(() => {
		router.push("/home");
	}, 3000);
}

const videoPopupToggled = () => {
	if (videoPopup.value) {		// Video was just opened
		welcomeVideo.value.play();
	} else {					// Video was just closed
		welcomeVideo.value.pause();
	}
};

</script>

<style>
.header-image {
	width: 100%;
	max-width: 1200px;
}
.header-title {
	text-align: center;
	text-transform: uppercase;
	width: 100%; max-width: 1200px;
	border-bottom: 4px double black;
}
.header-subtitle {
	text-align: center;
	width: 100%; max-width: 1200px;
	font-size: 20px;
}
.application-form {
	max-width: 1200px;
}
.application-form .existing-warning {
	text-align: center;
}
.simple-item-descriptive {
	padding: 0 20px;
	border-left: 6px solid;
	text-align: justify;
}
.simple-item-descriptive p {
	padding-right: 15px;
}
.simple-item-descriptive p+p {
	margin-top: 0;
}
.simple-item-videobox {
	display: flex;
	justify-content: center;
	color: #efefef;
	margin: 10px;
	font-size: 24px;
	text-align: center;
	background: #ccc;
	background-image: url(../assets/WelcomeVideo.th.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border: 2px solid #999;
	text-shadow: 2px 2px 3px #040404;
	cursor: pointer;
}
.dx-dateviewroller-day {
    display: none;
}
.welcome-popup .dx-popup-content {
	overflow: hidden;
}
.submitsurvey-error-body {
	white-space: pre-line;
	border-left: 2px solid red;
	padding-left: 15px;
}
</style>
