import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";

import App from "./App";
import appInfo from "./app-info";

import xior from 'xior';

themes.initialized(() => {
	const app = createApp(App);
	// Initialize our API link. This link is used for all REST calls except authentication, which is defined in auth.js.
	const $http = xior.create({
		baseURL: (appInfo.baseURL.endsWith('/') ? appInfo.baseURL.substr(0, appInfo.baseURL.length - 1) : appInfo.baseURL),
		headers: { 'Accept': 'application/json' },
	});
	app.provide('$http', $http);

	app.use(router);
	app.config.globalProperties.$appInfo = appInfo;
	app.mount('#app');
});
