<template>
	<DxTabPanel>
		<DxItem title="Application" icon="floppy">
			<div>
				<DxPopup v-model:visible="photoDisplayPopup" :hide-on-outside-click="true" :show-close-button="true">
					<img v-if="attachmentPhoto" width="99%" height="99%" :src="appInfo.storageURL + '/' + attachmentPhoto.store" style="object-fit:contain;" />
				</DxPopup>
				<div style="border: 1px solid black; margin: 20px; display:inline-block;">
					<a v-if="attachmentPhoto" href="#" @click.prevent="photoDisplayPopup = true">
						<img :src="appInfo.storageURL + '/' + attachmentPhoto.store" width="200" />
					</a>
					<div v-else class="applicant-no-photo">
						<br>
						<i class="dx-icon dx-icon-image" />
						<br><br>
					</div>
				</div>
				<div style="margin: 20px; display:inline-block;">
					<div>
						<DxLinearGauge :value="applicantStatusNumber">
							<DxScale :start-value="0" :end-value="10" :tick-interval="1">
								<DxTick :visible="false" />
								<DxLabel :visible="false" />
							</DxScale>
							<DxRangeContainer>
								<DxRange :start-value="0" :end-value="3" color="#92000A" />
								<DxRange :start-value="3" :end-value="7" color="#E6E200" />
								<DxRange :start-value="7" :end-value="10" color="#77DD77" />
							</DxRangeContainer>
							<DxValueIndicator type="textCloud" color="#f05b41">
								<DxText :customize-text="customizeStatusLabel" />
							</DxValueIndicator>
							<DxAnimation :duration="500">
							</DxAnimation>
						</DxLinearGauge>
					</div>
					<div>
						<span class="fieldName">External ID:</span> {{ props.applicant.external_id }}
					</div>
					<div>
						<span class="fieldName">Email:</span> <a :href="'mailto:' + props.applicant.email">{{ props.applicant.email }}</a>
					</div>
					<div>
						<span class="fieldName">Facebook:</span> <a :href="'https://www.facebook.com/' + props.applicant.facebook" target="_blank">{{ props.applicant.facebook }}</a>
					</div>
					<div>
						<span class="fieldName">Mobile:</span> <a :href="'skype:' + props.applicant.mobile">{{ props.applicant.mobile }}</a>
					</div>
				</div>
			</div>
		</DxItem>
		<DxItem title="Accounting" icon="money">
			<div style="margin: 20px 20px 20px 10px;border-left:5px solid #A0A0A0;padding: 20px 0 20px 10px;">
				<b>Payment status unknown.</b><br>
				<i>No payment or accounting details available.</i>
			</div>
		</DxItem>
		<DxItem title="Notes" icon="comment">
			<div style="margin: 20px 20px 20px 10px;border-left:5px solid #A0A0A0;padding: 20px 0 20px 10px;white-space:pre-line;">
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a risus eget turpis sollicitudin pulvinar. Vivamus augue urna, pulvinar non elit at, vestibulum porttitor tortor. Quisque ac dui blandit, pulvinar velit eget, tempor odio. Pellentesque dignissim nunc et tellus fringilla egestas vel a orci. Mauris feugiat, augue et accumsan dapibus, sapien ex mattis metus, eget congue quam urna id quam. Aenean justo tellus, suscipit id sem vitae, vehicula fringilla orci. Duis id euismod nulla, ut viverra mi. Ut maximus, felis vitae varius pulvinar, magna massa ultrices ante, ac tincidunt lectus sapien et felis. Mauris consectetur condimentum laoreet. Proin quis libero non ipsum porta varius. Ut iaculis lobortis diam elementum euismod.
			</div>
		</DxItem>
		<DxItem title="History" icon="columnfield">
			<div style="margin:20px; padding:20px; max-width:800px;">
				<div style="border: 1px solid gray; padding: 10px; margin-bottom: 10px;background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);">
					[2024/07/10 13:00] Application submitted
					<span style="display:block;float:right"><dx-button height="18px" icon="chevrondown" /></span>
				</div>
				<div style="border: 1px solid gray; padding: 10px; margin-bottom: 10px;background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);">
					[2024/07/10 11:45] Application updated, file uploaded
					<span style="display:block;float:right"><dx-button height="18px" icon="chevrondown" /></span>
				</div>
				<div style="border: 1px solid gray; padding: 10px; margin-bottom: 10px;background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);">
					[2024/07/10 11:30] Application created
					<span style="display:block;float:right"><dx-button height="18px" icon="chevrondown" /></span>
				</div>
			</div>
		</DxItem>
	</DxTabPanel>
</template>

<style>
.applicant-no-photo {
	width:200px;
	background: var(--dx-color-border);
	text-align: center;
}
.applicant-no-photo .dx-icon {
	text-decoration: line-through;
	text-decoration-color: var(--dx-color-primary);
}
</style>

<script setup>
import { defineProps, ref } from "vue";
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import { DxButton } from 'devextreme-vue/button';
import { DxLinearGauge, DxValueIndicator, DxRangeContainer, DxRange, DxScale, DxTick, DxLabel, DxText, DxAnimation } from 'devextreme-vue/linear-gauge';
import { DxPopup } from 'devextreme-vue/popup';
import appInfo from "../app-info";

const props = defineProps(['applicant']);
const applicantStatusNumber = 3;
const attachmentPhotos = props.applicant.attachments && Array.isArray(props.applicant.attachments) && props.applicant.attachments.length > 0 ?
	props.applicant.attachments.filter(a => a.filemime.toLowerCase().startsWith('image')) : null;
const attachmentPhoto = attachmentPhotos && attachmentPhotos.length >= 1 ? attachmentPhotos[0] : null;

const photoDisplayPopup = ref(false);

const customizeStatusLabel = function(labelData) {
	switch (labelData.value) {
		case 0:		return "Imported data";
		case 1:		return "Application filed";
		case 2:		return "Application checked";
		case 3:		return "Application OK";
		case 4:		return "Student accepted";
		case 5:		return "Student inducted";
		case 6:		return "Student processed";
		case 7:		return "Student waiting for results";
		case 8:		return "Examination failed";
		case 9:		return "Examination passed";
		case 10:	return "Alumni registration";
		default:	return "";
	}
}

</script>