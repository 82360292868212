export default [
	{ key: 'Hospital-Based Nursing', items: [
		{ name: 'Intensive Care Unit (ICU) Nurse' },
		{ name: 'Operating Room (OR) Nurse' },
		{ name: 'General Ward Nurse' },
		{ name: 'Medical-Surgical Ward Nurse' },
		{ name: 'Emergency Room (ER) Nurse' },
		{ name: 'Pediatric Nurse' },
		{ name: 'Obstetrics and Gynecology (OB-GYN) Nurse' },
		{ name: 'Psychiatric Nurse' },
		{ name: 'Neonatal Intensive Care Unit (NICU) Nurse' },
		{ name: 'Cardiac Care Unit (CCU) Nurse' },
		{ name: 'Burn Unit Nurse' },
		{ name: 'Infection Control Nurse' },
		{ name: 'Oncology Nurse' },
	] },
	{ key: 'Specialized Nursing', items: [
		{ name: 'Hemodialysis Nurse' },
		{ name: 'Dental Nurse' },
		{ name: 'Rehabilitation Nurse' },
		{ name: 'Research Nurse' },
	] },
	{ key: 'Long-Term Care and Community-Based Nursing', items: [
		{ name: 'Elderly Home Nurse' },
		{ name: 'Nursing Home Nurse' },
		{ name: 'Home Health Care Nurse' },
		{ name: 'Community Health Nurse' },
		{ name: 'Public Health Nurse' },
	] },
	{ key: 'Employer-Based Nursing', items: [
		{ name: 'Company Nurse' },
		{ name: 'Occupational Health Nurse' },
		{ name: 'School Nurse' },
	] },
	{ key: 'Outpatient and Advisory Nursing', items: [
		{ name: 'Outpatient Department (OPD) Nurse' },
		{ name: 'Telecommunication/Advisory Nurse' },
	] },
	{ key: 'Other', items: [
		{ name: 'Type any text...' },
	] },
];