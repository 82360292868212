<template>
	<div :class="{ 'dropzone-container': true, 'flex-box': true, 'minimal-dropzone': layout == 'minimal' }">
		<span class="title" v-if="label">{{ label }}</span>
		<hr v-if="label">
		<div :id="'dropzone-' + dropzoneId" class="flex-box dropzone-box" :class="[isDropZoneActive ? 'dropzone-active' : '']">
			<img class="dropzone-image" :src="imageSource" v-if="imageSource" alt="">
			<div class="dropzone-text flex-box" v-if="textVisible" >
				<span>Drag & Drop the desired file</span>
				<span>…or click to browse for a file instead.</span>
			</div>
			<DxProgressBar class="dropzone-progress" :min="0" :max="100" width="30%" :show-status="false" :visible="progressVisible" :value="progressValue" />
		</div>
		<DxFileUploader :visible="false" :dialog-trigger="'#dropzone-' + dropzoneId" :drop-zone="'#dropzone-' + dropzoneId"
			:multiple="false" upload-mode="instantly" :upload-url="appInfo.baseURL + '/attachment'" :upload-headers="{ 'accept': 'application/json' }"
			@drop-zone-enter="onDropZoneEnter" @drop-zone-leave="onDropZoneLeave" @uploaded="onUploaded" @progress="onProgress" @upload-started="onUploadStarted"
		/>
	</div>
</template>

<script setup>
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxProgressBar } from 'devextreme-vue/progress-bar';
import { ref, defineProps, defineEmits, toRefs, onMounted } from 'vue';
import appInfo from "../app-info";

const props = defineProps([
	'label',					// Text label used for title (optional)
	'layout',					// Layout string, one of 'square' (default), 'minimal'.
	'initial',					// Initial attachment settings (for setting preview for existing data)
]);
const { label, layout } = toRefs(props);
const emits = defineEmits(["valueChanged"]);

const isDropZoneActive = ref(false);
const imageSource = ref('');
const textVisible = ref(true);
const progressVisible = ref(false);
const progressValue = ref(0);

onMounted(() => {
	if (props.initial) {
		console.log(props.initial);
		isDropZoneActive.value = false;
		imageSource.value = String(appInfo.storageURL + '/' + props.initial.store);
		textVisible.value = false;
		progressVisible.value = false;
		progressValue.value = 0;
	}
});

// Generate a unique ID to be used as DOM ID for DxFileUploader.
const dropzoneId = ref(crypto.randomUUID().replaceAll('-', ''));

function onDropZoneEnter({ component, dropZoneElement, event }) {
	if (dropZoneElement.id.startsWith('dropzone-')) {
		const items = event.originalEvent.dataTransfer.items;
		const allowedFileExtensions = component.option('allowedFileExtensions');
		const draggedFileExtension = `.${items[0].type.replace(/^image\//, '')}`;
		const isSingleFileDragged = items.length === 1;
		const isValidFileExtension = allowedFileExtensions.includes(draggedFileExtension);

		if (isSingleFileDragged && isValidFileExtension) {
			isDropZoneActive.value = true;
		}
	}
}
function onDropZoneLeave(e) {
	if (e.dropZoneElement.id.startsWith('dropzone-')) {
		isDropZoneActive.value = false;
	}
}

function onUploaded({ file, request }) {
	const fileReader = new FileReader();
	fileReader.onload = () => {
		isDropZoneActive.value = false;
		imageSource.value = String(fileReader.result);
	};

	fileReader.readAsDataURL(file);
	textVisible.value = false;
	progressVisible.value = false;
	progressValue.value = 0;

	let jsonResponse = JSON.parse(request.response);
	console.log(jsonResponse);
	emits('valueChanged', jsonResponse);
}
function onProgress(e) {
	progressValue.value = (e.bytesLoaded / e.bytesTotal) * 100;
}
function onUploadStarted() {
	imageSource.value = '';
	progressVisible.value = true;
}
</script>

<style>
.dropzone-container > .title {
	font-size: 20px;
	margin-bottom: 0.5em;
	z-index: 1;
	background: var(--dx-color-main-bg);
	padding: 0 10px;
}
.dropzone-container > hr {
	width: 100%;
	margin-top: -1.75em;
	margin-bottom: 1.75em;
	border: 0;
	border-top: 1px solid black;
}
.flex-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.dropzone-box {
	width: 350px;
	height: 350px;
	background-color: rgb(183 183 183 / 10%);
	border-width: 2px;
	border-style: dashed;
	padding: 10px;
	border-color: var(--dx-color-border);
}
.dropzone-container.minimal-dropzone .dropzone-box {
	height: auto !important;
}
.dropzone-box > * {
	pointer-events: none;
}
.dropzone-box.dropzone-active {
	border-style: solid;
	border-color: var(--dx-color-primary);
}
.dropzone-image {
	max-width: 100%;
	max-height: 100%;
}
.dropzone-text > span {
	font-weight: 100;
	opacity: 0.5;
}
.dropzone-progress {
	display: flex;
	margin-top: 10px;
}
</style>
